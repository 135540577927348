import React, { Component } from 'react';

class ShowcaseModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jobImageIndex: 1
    }
  }

  pageFlipRight() {
    if (this.state.jobImageIndex < this.props.jobData.images.length) {
      this.setState({
        jobImageIndex: this.state.jobImageIndex + 1
      });
    } else {
      // We've seen all the images, close and return.
      // Not super intuitive, see TODOs.
      this.props.hideModal();
    }
  }

  // TODO: implement image flipper, as this does nothing for the moment
  pageFlipLeft() {
    this.setState({
      jobImageIndex: Math.max(this.state.jobImageIndex - 1, 1)
    });
  }

  render() {
    let imgPath = this.props.imgPath + this.props.jobKey;
    let currentImageData = this.props.jobData.images[this.state.jobImageIndex-1];

    return (
      <div>
        <div id="itemShowcase">
          <div id="showcaseClose" onClick={this.props.hideModal}>close</div>
          <div id="itemShowcaseContainer">
            <div class="itemContainer">
              <div class="itemLabel">
                {currentImageData.name}
              </div>
              <div
                class="itemImage"
                onClick={(e) => this.pageFlipRight()}>
                <img
                  src={`${imgPath}/img${this.state.jobImageIndex}.jpg`}
                  alt={currentImageData.name}
                />
              </div>
              <div class="itemDescription">
                {currentImageData.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowcaseModal;

import React, { Component } from 'react';
import './App.css';
import InfoBox from './InfoBox';
import FeatureBox from './FeatureBox';
import ShowcaseModal from './ShowcaseModal';
import jsonBlob from './jobJson.js';

const imgPath = process.env.PUBLIC_URL + '/assets/images/';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jobData: jsonBlob(),
      showModal: false,
      jobIndex: ''
    }
    this.showOverview = this.showOverview.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showOverview(showOverviewFlag, jobIndex) {
    this.setState({
        showModal: showOverviewFlag,
        jobIndex: jobIndex
      });
  }

  hideModal() {
    this.setState({
      showModal: false
    });
  }

  render() {
    var jorbs = Object.keys(this.state.jobData).map(function(jobKey) {
      return (
        <FeatureBox
          imgPath={imgPath}
          showOverview={this.showOverview}
          key={jobKey}
          jobKey={jobKey}
          jobData={this.state.jobData[jobKey]}
        />
      );
    }, this);
    return (
      <div>
      { !this.state.showModal &&
        <div id="mainContent">
          <InfoBox />
          <h2>Showcase</h2>
          { jorbs }
        </div>
      }
      { this.state.showModal &&
        <div id="showcaseContent">
          <ShowcaseModal
            imgPath={imgPath}
            hideModal={this.hideModal}
            jobKey={this.state.jobIndex}
            jobData={this.state.jobData[this.state.jobIndex]}/>
        </div>
      }
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';

class FeatureBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showOverview() {
    this.props.showOverview(true, this.props.jobKey);
  }

  render() {
    return (
      <div
        className="showcaseItem"
        onClick={(e) => this.showOverview()}>
        <h4>{this.props.jobData.name}</h4>
        <em>{this.props.jobData.skills}</em>
        <img src={`${this.props.imgPath}${this.props.jobKey}/${this.props.jobKey}.png`} alt="description here"/>
        <p>{this.props.jobData.description}</p>
      </div>
    );
  }
}

export default FeatureBox;

export default function jsonBlob() {
  return ({
    "meridian": {
      "name": "Aruba Meridian Location Services (2019-)",
      "skills": "Python, Django, Google Cloud, CI/CD, Docker, Kubernetes",
      "description": "Initially brought on to develop features for Meridian Campus guidance apps, I eventually used my cloud computing skills to develop a pre-prod environment solution for the entire backend team to enable continuous development and integration for several of Meridian's products.",
      "images": [
        {
          "name": "Location mapping service",
          "description": "Meridian's location services, for which I eliminated the hands-on approach to deployment and integration."
        }
      ]
    },
    "aws": {
      "name": "Elemental Media Store (2017-2019)",
      "skills": "AWS Technologies, Java, Python, React, Ruby",
      "description": "Console frontend and feature work for a native AWS service intended as a high performance, low latency origin. While I was originally brought on to launch the console for EMS, I eventually started work on the service and contributed to several features intended to flesh out a new service offering.",
      "images": [
        {
          "name": "Container overview",
          "description": "Containers are the top level constructs for EMS. They are both for organization and for option management - policies and settings are typically scoped at container level."
        },
        {
          "name": "Creation modal",
          "description": "In order to get the user up and running in the simplest possible way, there is only a single barrier to starting to use EMS - container creation. This step provisions all the necessary resources for a customer to be able to use EMS."
        },
        {
          "name": "Inner container view",
          "description": "From here a user can create folders or add items, or abandon the console and go straight to the SDK or CLI using the settings and policies adjusted and displayed here. Overcoming the eventually consistent and nondeterministic nature of the service listing API was challenging."
        }
      ]
    },
    "eti": {
      "name": "Elemental LSCS product family (2013-2017)",
      "skills": "Ruby on Rails, SQL, Angular, jQuery",
      "description": "Appliance ecosystem wrapper around a feature-rich video encoder. The Elemental systems run a dual Ruby on Rails layer in order to interface with both the user and the machine encoder, so I got plenty of practice in both developing the UI as well as dealing with operating system-like features. All information comes from backend that interacts with the encoder through stdio.",
      "images": [
        {
          "name": "Live appliance UI",
          "description": "Primary view of running live video events. This view is refreshed continuously by polling and updates the state of each event and keeps track of system health (memory, CPU and network usage). Each event can be expanded for a more detailed view."
        },
        {
          "name": "Output groups and streams",
          "description": "A single output group can have many streams, and there can be many output groups. Organizing all this information can be challenging."
        },
        {
          "name": "Adobe RTMP output group",
          "description": "One of the simpler output groups and related encoder options."
        }
      ]
    },
    "quango1" : {
      "name": "Ametek Product Manager (2013)",
      "skills": "PHP, jQuery, Yii, CSS, mySQL",
      "description": "A product manager system completed using an existing framework (Yii) and a downloadable theme (Adminus). The challenge was to write less code and use existing technology to complete the task within 2 weeks. I have not used Yii before, but its code generation tools and flexible association model fit the need perfectly. Completion took 9 days.",
      "images": [
        {
          "name": "Sortable product overview",
          "description": "This implements the Yii data manager with live search and sort. Appearance was themed with a third-party downloadable theme that needed to be integrated with Yii defaults. Theme style included inputs, navigation, overall look and feel of page, AJAX prompts, etc."
        },
        {
          "name": "Multi-tabbed form entry.",
          "description": "I had to tweak Yii to allow me to include multiple models (product, photos, documents) onto one 'view', which took a little longer than expected. Still, I was pleased with the results: top tabs determine type of product details to be edited, side tabs switch between product info panels."
        },
        {
          "name": "Document association page.",
          "description": "This page lets you attach documentation to products. The only tricky parts were duplicate attachment prevention, a check for unique and active documents, and preventing the user from trying to add/remove without selecting anything. The lower half includes a live search - just start typing to get a list of available documents."
        },
        {
          "name": "Resulting dynamic product page.",
          "description": "This view reads the product id, loads appropriate info, fetches associated documents and images and creates a product overview page with downloads and a slideshow."
        }
      ]
    },
    "labs": {
      "name": "InstantAction Labs (2010)",
      "skills": "PHP, jQuery, REST API, mySQL",
      "description": "IA Labs was a multi-client content management system intended as a complete solution for managing a next generation game network website. A developer or publisher would be able to log in, manage, preview and publish their content. The system also included user lookup and management; hierarchical permissions and game management were planned as well.",
      "images": [
        {
          "name": "Customer management center",
          "description": "Multi purpose panel allowing access to user parameters, a banlist, and a live feed of most recent actions taken within the system. The live feed would switch to most recent actions on specific user once user was found."
        },
        {
          "name": "Customer management - ownership management",
          "description": "Modal view for granting products, confirmation screen. Actual UI allowed the admin to live search from a list of available products; ownership was granted in realtime."
        },
        {
          "name": "E-commerce catalog management",
          "description": "From this screen the admin could access all items belonging to them, their properties, and their e-commerce status. You could change names, prices, or methods of purchase, and even collect items into bundles, gift packs, or promo options. Changes were packaged and deployed as a unit pending workflow review."
        }
      ]
    },
    "somi": {
      "name": "Secret of Monkey Island (Facebook) (2009)",
      "skills": "PHP, jQuery, REST API, Facebook",
      "description": "Secret of Monkey Island was our first very public third party integration, created with social features in mind. It was also the first foray into e-commerce, using our own platform and API. We switched to jQuery due to Ext.js being too heavy, but the bulk of work went into Facebook integration.",
      "images": [
        {
          "name": "Embeddable view",
          "description": "This widget-sized layout could be inserted into any page that allowed iframes, and the game could be played from here with the help of a crossdomain channel and some javascript events. The bottom bar housed control, sign-in and purchase popups."
        },
        {
          "name": "Facebook full app view",
          "description": "On the game's homepage on Facebook, a user could browse forums and game information as well as play the game. There were also friends' presence widgets, achievement comparisons, and an embed widget URL generator."
        }
      ]
    },
    "legions": {
      "name": "PlayLegions.com (2009)",
      "skills": "PHP, Ext.js, REST API, Blueprint, Facebook",
      "description": "PlayLegions was a standalone proof of concept site that enabled users to play a racing game in their browser. Users were also able to store high scores per track, and play the game on its own site as well as on Facebook with all standard Facebook functionality such as friends list, invites and challenges. Site is no longer active as the company dissolved.",
      "images": [
        {
          "name": "Legions front page",
          "description": "Navigation menu with standard Facebook items. Note friend widget on bottom powered by jQuery and Facebook API."
        },
        {
          "name": "Scoreboard and course selector",
          "description": "Scoreboard with current scores and top players. Due to the complexity of this widget the routes were loaded first, and the users and scores later. This one was a team effort."
        },
        {
          "name": "Friend and global scoreboard",
          "description": "This widget grabbed your friends from Facebook and their scores from the game; its secondary function was to grab the top times as well, so it also did this process in reverse (first the top scores, then the corresponding users). It would also auto-scroll to your own score in either list."
        }
      ]
    },
    "ddweller" : {
      "name": "Dungeon Dweller (WIP)",
      "skills": "AS3",
      "description": "A roguelike written using Flashpunk and AS3 in FlashDevelop. It currently is able to generate dynamic levels on the fly; generate pathways using A* pathfinding, and generate random items and place them using Flashpunk's collision detection. Player movement and item detection is in as well; next up is player inventory. Pixel art by ADC; placeholder art mine.",
      "images": [
        {
          "name": "A typical level.",
          "description": "A sample level. All rooms, corridors, items and player are placed dynamically on each level. All code and placeholder item art by me; all pixel art by ADC. Note that image shows pre-alpha debug information."
        }
      ]
    },
    "wt": {
      "name": "Wicked Toast (~2003)",
      "skills": "PHP, mySQL",
      "description": "Wicked Toast was a hobby game review site which used a quick and dirty UI to let editors and writers add and manage content quickly. I used the \"widget\" concept to quickly shift content around as I experimented with the layout, but the widgets were simple file includes. The most difficult part of this was the XML parser; I wrote my own as theSimpleXML library did not exist yet.",
      "images": [
        {
          "name": "Initial admin page",
          "description": "Simple menu with all used functions on top. This screen gets you into the most commonly used functions, i.e. the creation of new content or editing of previous content."
        },
        {
          "name": "News admin",
          "description": "Simple news entry form; username pre-populated from login credentials. UI allows for before/after the fold content, source-citing, and media uploads. Again, the most common functions are all available from one form."
        },
        {
          "name": "Article Management",
          "description": "This screen lets you manage not only the text content on the site but also the layout in a limited fashion. You can quickly update timestamps on content as needed, turn featured content on and off, and toggle visibility status. There's also a preview mode for proofreading by other editors."
        },
        {
          "name": "Editors' page with widgets",
          "description": "This screen illustrates the widget-driven concept with a total of 8 widgets all tied to current editor displayed: latest content on the left, and personal stats and information on the right. In the content column the bio and mini-blog are content-managed as well."
        },
        {
          "name": "Site home with reduced widget view",
          "description": "Site homepage illustrating a reduced amount of widgets: layout can be experimented with by simply including or commenting out relevant widgets without fear of losing dependencies."
        }
      ]
    },
    "dnet": {
      "name": "D.net CMS (~2000)",
      "skills": "PHP, mySQL, jQuery",
      "description": "A CMS focused on getting the user up and running in minutes. Packaged with a quick install process and using a WYSIWYG interface in an era of obtuse dropdowns and configuration checkboxes, D.net let you edit, add, remove and move widgets from where they were on the page with single button clicks.",
      "images": [
        {
          "name": "Admin vs. live view",
          "description": "Admin view on top, and actual page layout below. Note widget dropdowns - new widgets will appear in those respective columns. Note widget variety in dropdown."
        },
        {
          "name": "Widget admin",
          "description": "Modular widget control. Each widget contains everything it needs to perform its own administration and rendering. This lets the developer(s) simply drop in new widgets without worrying about dependencies (of course it means a lot of redundant code)."
        },
        {
          "name": "Admin view with content",
          "description": "Admin after mere moments of adding widgets. It was intended a user is up and running almost immediately with the basic widgets."
        },
        {
          "name": "Live view with content",
          "description": "Live view after enabling widgets; widgets start out in disabled state so that they can be configured. A single click enables them for use."
        }
      ]
    }
  });
}

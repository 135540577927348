import React, { Component } from 'react';

class InfoBox extends Component {
  render() {
    return (
      <div>
        <h2>Marcin Manek</h2>
          <span className="label">Contact</span>: marcinmm@fastmail.fm<br/>
          <span className="label">Places</span>: <a href="http://www.linkedin.com/in/marcinmanek">LinkedIn</a>, <a href="https://github.com/MarcinMM">GitHub</a>
        <div className="subContent">
          <h3>Things I Can Do</h3>
          <span className="label">Technologies</span>: Python, Docker, Kubernetes, React, Ruby, Rails, JS.<br/>
          <span className="label">Skills</span>: AWS services and SDKs, GoogleCloud services and SDKs, CI/CD, test driven development, agile methodologies.<br/>
        </div>
      </div>
    );
  }
}

export default InfoBox;
